import { connectKG } from "@knowgistics/core";
import "@knowgistics/core/dist/index.css"
import { firebaseConfig } from "Controller/firebase";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";

import { PagePostEdit } from "Pages/Post/Edit";
import { PagePostList } from "Pages/Post/List";
import { PagePostView } from "Pages/Post/View";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/edit/:id" component={PagePostEdit} />
        <Route path="/view/:id" component={PagePostView} />
        <Route path="/" component={PagePostList} />
      </Switch>
    </BrowserRouter>
  );
}

export default connectKG({
  firebaseConfig,
  Link,
  showCollect: true,
  baseDomain: 'phra.in',
})(App);
