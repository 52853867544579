import { ContentEdit, KGContext } from "@knowgistics/core";
import { db, dbTimestamp } from "Controller/firebase";
import { createContext, useContext, useEffect, useState } from "react";

class Controller {
  constructor(id, user) {
    this.user = user;
    this.id = id;
  }
  get = async () => {
    if (this.user && this.id) {
      const data = (await db.collection("documents").doc(this.id).get()).data();
      return data && data.user === this.user.uid ? data : null;
    }
    return null;
  };
  update = async (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    return db
      .collection("documents")
      .doc(this.id)
      .update({ ...newData, datemodified: dbTimestamp() });
  };
}

const Context = createContext({});
const Provider = ({ children, ...props }) => {
  const { user, t } = useContext(KGContext);
  const [data, setData] = useState();
  const [control, setControl] = useState();
  const config = {
    prefix: "mek-",
    show: ["title", "feature", "heading", "paragraph", "image", "vdo"],
  };

  const store = {
    ...props,
    ...props.match.params,
    config,
    data: [data, setData],
    control,
    user,
    t,
  };

  useEffect(() => {
    if (user && store.id) {
      const control = new Controller(store.id, user);
      setControl(control);
      return control.get().then((data) => setData(data));
    } else {
      setData(null);
    }
  }, [user, store.id]);

  return <Context.Provider value={store}>{children}</Context.Provider>;
};
const connect = (Comp) => (props) =>
  <Provider {...props} children={<Comp {...props} />} />;

export const PagePostEdit = connect((props) => {
  const store = useContext(Context);
  const [data, setData] = store.data;

  const handleSave = async (n) => {
    await store.control.update(n);
    setData(n);
  };

  return (
    <ContentEdit
      loading
      config={store.config}
      data={data}
      onSave={handleSave}
    />
  );
});
