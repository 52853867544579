import {
  faEdit,
  faImage,
  faPlus,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Container,
  ContentHeader,
  DateToStr,
  DialogPrompt,
  DialogRemove,
  DuotoneButton,
  KGContext,
  MainContainer,
  TableDocs,
} from "@knowgistics/core";
import { Avatar, Box, Link as MLink } from "@material-ui/core";
import { db, dbTimestamp } from "Controller/firebase";
import { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

class Controller {
  constructor(user) {
    this.user = user;
  }
  watch = (callback) => {
    return db
      .collection("documents")
      .where("type", "==", "post")
      .where("user", "==", this.user.uid)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          modified:
            doc.data().datemodified && doc.data().datemodified.toMillis
              ? doc.data().datemodified.toMillis()
              : Date.now(),
        }));
        callback(docs);
      });
  };
  create = async (title) => {
    if (this.user && this.user.uid) {
      try {
        const result = await db.collection("documents").add({
          type: `post`,
          title,
          datecreate: dbTimestamp(),
          datemodified: dbTimestamp(),
          user: this.user.uid,
        });
        return result;
      } catch (err) {
        return { error: err.message };
      }
    } else {
      return { error: "please sign in" };
    }
  };
  remove = async (id) => {
    await db.collection("documents").doc(id).update({ type: "post-remove" });
  };
}

const Context = createContext({});
const Provider = ({ children, ...props }) => {
  const { user, addAlert, t } = useContext(KGContext);
  const [state, setState] = useState({
    fetched: false,
  });
  const [control, setControl] = useState();
  const store = {
    ...props,
    ...props.match.params,
    state: [state, setState],
    control,
    user,
    addAlert,
    t,
  };
  useEffect(() => {
    if (user) {
      const control = new Controller(user);
      setControl(control);
      return control.watch((docs) =>
        setState((s) => ({ ...s, docs, fetched: true }))
      );
    }
  }, [user]);
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
const connect = (Comp) => (props) =>
  <Provider {...props} children={<Comp {...props} />} />;

const AddButton = () => {
  const { control, addAlert, t, history } = useContext(Context);
  const [value, setValue] = useState("");
  const handleAdd = async (title) => {
    setValue(title);
    const result = await control.create(title);
    if (result.error) {
      addAlert({ label: `${result.error}`, severity: "error" });
    } else {
      history.push(`/edit/${result.id}`);
    }
  };

  return (
    <DialogPrompt
      value={value}
      title="Add Post"
      label="Post Title"
      onConfirm={handleAdd}
    >
      <DuotoneButton icon={faPlus}>{t("Add")}</DuotoneButton>
    </DialogPrompt>
  );
};

export const PagePostList = connect((props) => {
  const { t, control, addAlert, ...store } = useContext(Context);
  const [state, setState] = store.state;

  const handleRemove = (remove) => () => setState((s) => ({ ...s, remove }));
  const handleRemoveConfirm = async () => {
    setState(s=>({ ...s, remove:null }));
    await control.remove(state.remove.id);
    addAlert({label:'Remove post success'})
  };

  const config = {
    fetched: state.fetched,
    checkbox: true,
    search: ["title"],
    docs: state.docs,
    rowId: "id",
    defaultSort: "modified",
    defaultOrder: "desc",
    columns: [
      {
        id: "cover",
        value: (row) => (
          <Avatar variant="square">
            {row.cover ? (
              <img
                src={row.cover.thumbnail || row.cover.medium || row.cover.large}
                alt={`thumb-${row.id}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <FontAwesomeIcon icon={faImage} />
            )}
          </Avatar>
        ),
        padding: "none",
      },
      {
        label: t("Title"),
        id: "title",
        value: (row) => (
          <MLink component={Link} to={`/view/${row.id}`} target="_blank">
            {row.title}
          </MLink>
        ),
        sortable: true,
        width: 70,
      },
      {
        id: "modified",
        label: t("LastModified"),
        value: (row) => DateToStr(row),
        width: 25,
        align: "center",
        sortable: true,
      },
      {
        id: "actions",
        value: (row) => {
          return (
            <Box display="flex">
              <ActionIcon
                icon={faEdit}
                component={Link}
                to={`/edit/${row.id}`}
              />
              <ActionIcon
                icon={faTrash}
                color="secondary"
                onClick={handleRemove(row)}
              />
            </Box>
          );
        },
        width: 5,
      },
    ],
  };

  return (
    <MainContainer signInOnly>
      <Container maxWidth="md">
        <ContentHeader label="Post" actions={<AddButton />} />
        <TableDocs {...config} />
      </Container>
      <DialogRemove
        title={t("Remove")}
        label={t("Courses.DoYouWantToRemove", {
          name: state.remove ? state.remove.title : "",
        })}
        open={Boolean(state.remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </MainContainer>
  );
});
