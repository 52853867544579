import { ContentView } from "@knowgistics/core";
import { db } from "Controller/firebase";
import { useEffect, useState } from "react";

export const PagePostView = (props) => {
  const { id } = props.match.params;
  const [state, setState] = useState({
    fetched: false,
    data: {},
  });

  useEffect(() => {
    if (id) {
      db.collection("documents")
        .doc(id)
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          setState((s) => ({ ...s, fetched: true, data }));
        });
    }
  }, [id]);

  return (
    <ContentView
      loading={!state.fetched}
      breadcrumbs={[{ label: "Home", to: "/" }]}
      data={state.data}
    />
  );
};
